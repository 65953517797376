<template>
  <div class="all_main_wrap">
    <div class="all_header_box">
      <div class="sider_left">
        <span class="title">
          <router-link class="crumbs_item" tag="a" to="/admin/PromotionManage/newCouponManage">优惠券管理 / </router-link>
          <span class="crumbs_item crumbs_last">{{operation==1?'新建满减券':operation==2?'编辑满减券':'满减券详情'}}</span>
        </span>
      </div>
    </div>
    <div class="all_content_box">
      <div class="L_R_inner">
        <span class="all_left_name all_required">优惠券名称：</span>
        <div class="right_Div">
          <a-input class="all_input" :max-length="40" v-model="form.name" placeholder="如卡罗兰纳优惠券，最多40个字" />
        </div>
      </div>
      <div class="L_R_inner">
        <span class="all_left_name">优惠券描述：</span>
        <div class="right_Div">
          <a-textarea  class="all_input" v-model="form.description" :auto-size="{ minRows: 3, maxRows: 7 }" placeholder="用于后台记录管理的优惠券描述" />
        </div>
      </div>
      <div class="L_R_inner">
        <span class="all_left_name all_required">领取说明：</span>
        <div class="right_Div">
          <a-textarea  class="all_input" v-model="form.receiveDesc" :auto-size="{ minRows: 3, maxRows: 7 }" placeholder="当该优惠券设置了“用户领取”后，需填写“领取说明”" />
        </div>
      </div>
      <div class="L_R_inner">
        <span class="all_left_name all_required">优惠券类型：</span>
        <div class="right_Div wrap_Div">
          <a-radio :checked="true">满减券（可设置满额金额和抵扣金额；若需设置无门槛优惠券立减券，及满额限制为0）</a-radio>
          <div class="child_Div">
            <div>满额金额 <a-input-number :disabled="isReceive" v-model="form.fullPrice" placeholder="元" :min="0" :max="999999999"/></div>
            <div>优惠金额 <a-input-number :disabled="isReceive" v-model="form.couponPrice" placeholder="元" :min="0" :max="999999999"/></div>
          </div>
        </div>
      </div>
      <div class="L_R_inner">
        <span class="all_left_name all_required">数量控制：</span>
        <a-radio-group name="radioGroup" class="right_Div" v-model="form.noVolumes">
          <a-radio style="margin-right:50px" :value="0">无限制</a-radio>
          <a-radio :value="1">设置优惠券数量 <a-input-number v-show="form.noVolumes" v-model="form.volumes" placeholder="张" :min="minVolumes" :max="999999999"/></a-radio>
        </a-radio-group>
      </div>
      <div class="L_R_inner">
        <span class="all_left_name all_required">适用商品：</span>
        <div class="right_Div wrap_Div">
          <a-radio-group name="radioGroup" v-model="form.scope">
            <a-radio style="margin-right:50px" :value="1">全场通用</a-radio>
            <a-radio style="margin-right:50px" :value="3">指定商品可用</a-radio>
          </a-radio-group>
          <a-button type="primary" v-show="form.scope == 3" @click="popupFilling()">新建</a-button>
          <div class="child_Div" v-show="form.scope == 3">
            <a-table
              bordered
              class="table-template"
              :rowKey="(item,index) => index"
              :columns="productColumns"
              :data-source="tableData"
              :pagination="false"
            >
              <template slot="index" slot-scope="item, row, index">
                {{ index + 1 }}
              </template>
              <template slot="productType" slot-scope="item">
                <!-- 1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播 9.其他 -->
                <span v-if="item==1">课程</span>
                <span v-else-if="item==2">图书</span>
                <span v-else-if="item==3">教具</span>
                <span v-else-if="item==4">模拟考试</span>
                <span v-else-if="item==5">电子照片</span>
                <span v-else-if="item==6">延期</span>
                <span v-else-if="item==7">补考</span>
                <span v-else-if="item==8">直播</span>
                <span v-else-if="item==9">其他</span>
                <span v-else>-</span>
              </template>
              <template slot="operation" slot-scope="item, row, index">
                <span class="blueText">
                  <span v-if="row.isReceive != 1" @click="onDel(index)">删除</span>
                  <span v-else>-</span>
                </span>
              </template>
            </a-table>
          </div>
        </div>
      </div>
      <div class="L_R_inner">
        <span class="all_left_name all_required">优惠叠加：</span>
        <a-radio-group name="radioGroup" class="right_Div" :disabled="isReceive" v-model="form.overly">
          <a-radio style="margin-right:50px" :value="1">允许和其他优惠券叠加使用</a-radio>
          <a-radio :value="0">不允许和其他优惠券叠加使用</a-radio>
        </a-radio-group>
      </div>
      <div class="L_R_inner">
        <span class="all_left_name">公开设置：</span>
        <div class="right_Div">
          <a-checkbox v-model="publicConfig">用户可在商品详情页领取</a-checkbox>
        </div>
      </div>
      <div class="L_R_inner">
        <span class="all_left_name all_required">用户领取条件：</span>
        <div class="right_Div wrap_Div">
          <a-radio-group name="radioGroup" v-model="form.astrict">
            <a-radio style="margin-right:50px" :value="2">不可领取</a-radio>
            <a-radio style="margin-right:50px" :value="0">全部可领</a-radio>
            <!-- <a-radio style="margin-right:50px" :value="1">指定领取</a-radio> -->
          </a-radio-group>
          <div class="child_Div" v-show="form.astrict == 1">
            <a-table
              bordered
              class="table-template"
              :rowKey="(item,index) => index"
              :columns="userColumns"
              :data-source="tableData"
              :pagination="false"
            >
            <template slot="index" slot-scope="item, row, index">
                {{ index + 1 }}
              </template>
              <template slot="operation">
                <span class="blueText">
                  <span>删除</span>
                </span>
              </template>
            </a-table>
          </div>
        </div>
      </div>
      <div class="L_R_inner">
        <span class="all_left_name all_required">领取时间：</span>
        <a-radio-group name="radioGroup" class="right_Div" v-model="form.receiveAging">
          <a-radio style="margin-right:50px" :value="1">长期</a-radio>
          <a-radio :value="2">
            指定时间范围 
            <template v-if="form.receiveAging == 2">
              <a-date-picker valueFormat="YYYY-MM-DD HH:mm:ss" format="YYYY-MM-DD HH:mm:ss" v-model="form.startLed" placeholder="设置领取开始时间"  />
              ~
              <a-date-picker valueFormat="YYYY-MM-DD HH:mm:ss" format="YYYY-MM-DD HH:mm:ss" v-model="form.endLed" placeholder="设置领取结束时间"  />
            </template>
          </a-radio>
        </a-radio-group>
      </div>
      <div class="L_R_inner">
        <span class="all_left_name all_required">使用时间：</span>
        <a-radio-group name="radioGroup" class="right_Div" :disabled="isReceive" v-model="form.times">
          <a-radio style="margin-right:50px" :value="1">长期</a-radio>
          <a-radio style="margin-right:50px" :value="2">
            指定时间范围 
            <template v-if="form.times == 2">
              <a-date-picker valueFormat="YYYY-MM-DD HH:mm:ss" :disabled="isReceive" format="YYYY-MM-DD HH:mm:ss" v-model="form.startTime" placeholder="设置使用开始时间"  />
              ~
              <a-date-picker valueFormat="YYYY-MM-DD HH:mm:ss" :disabled="isReceive" format="YYYY-MM-DD HH:mm:ss" v-model="form.endTime" placeholder="设置使用结束时间"  />
            </template>
          </a-radio>
          <a-radio :value="3">领取后 <a-input-number placeholder="天" :disabled="form.times!=3 || isReceive" v-model="form.days" :min="0" :max="999999999"/>天内有效</a-radio>
        </a-radio-group>
      </div>
    </div>
    <div class="button">
      <a-button type="primary" :loading="PreventLoad" class="btn" @click="onSave">{{operation == 1 ? '创建' : '编辑'}}</a-button>
      <a-button class="btn" @click="$router.go(-1)">取消</a-button>
    </div>

    <a-modal v-model="productModalShow" width='1000px' title="选择商品" :maskClosable='false' :centered='true' :closable='false'>
      <div class="search-box">
        <a-select class="search-input"  placeholder="请选择类型" v-model="categorytype" allowClear>
          <a-icon slot="suffixIcon" type="caret-down" />
            <a-select-option v-for="item in allGoodList" :key="item.id" :value="item.id">
              {{ item.classify }}
            </a-select-option>
        </a-select>
        <a-input class="search-input" placeholder="请输入名称" v-model="categoryname" allowClear/>
        <a-button class="search-btn" type="primary"  @click="onSearch()">搜索</a-button>
      </div>

      <div class="table-box">
        <a-table class="table-template" 
          :row-selection="{
            columnTitle:'选择',
            selectedRowKeys: selectedRowKey,
            onChange: onSelect,
            getCheckboxProps:disCheckboxProps,
          }"
          :rowKey="(item) => item.productId" 
          :columns="categorycolumns" 
          :loading="categoryLoad"
          :data-source="categoryData" 
          :pagination="{
            total: ctotal,
            current: cpageNumber,
            pageSize: cpageSize,
            showTotal: (res) => `共${ctotal}条`,
          }" 
          bordered 
          @change="oncPage">
          <template slot="num">
            商品名称
          </template>
          <template slot="index" slot-scope="item, row, index">
            {{ (cpageNumber - 1) * cpageSize + index + 1 }} 
          </template>
          <template slot="productType" slot-scope="item">
            <!-- 1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播 9.其他 -->
            <span v-if="item==1">课程</span>
            <span v-else-if="item==2">图书</span>
            <span v-else-if="item==3">教具</span>
            <span v-else-if="item==4">模拟考试</span>
            <span v-else-if="item==5">电子照片</span>
            <span v-else-if="item==6">延期</span>
            <span v-else-if="item==7">补考</span>
            <span v-else-if="item==8">直播</span>
            <span v-else-if="item==9">其他</span>
            <span v-else>-</span>
          </template>
        </a-table>
        <a-table class="table-template" 
          :rowKey="(item) => item.productId" 
          :columns="cselectcolumns" 
          :data-source="selectData" 
          :pagination="{
            pageSize: spageSize,
          }">
          <template slot="num">
            当前已选中 <span class="blueText">{{selectData == null?'0':selectData.length}}</span> 个商品
          </template>
          <template slot="operation" slot-scope="item, row">
            <span class="blueText" v-if="row.isReceive != 1" @click="deleteGoods(row)">清除</span>
            <span class="blueText" v-else>-</span>
          </template>
        </a-table>
      </div>

      <template slot="footer">
        <a-button type="primary" @click="categoryOk">确认</a-button>
        <a-button @click="categoryCancel">取消</a-button>
      </template>

    </a-modal>
  </div>
</template>

<script>
const productColumns = [
  {
    title: "序号",
    align: "center",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "商品名称",
    dataIndex: "productName",
    align: "center",
  },
  {
    title: "商品分类",
    dataIndex: "productType",
    align: "center",
    scopedSlots: {
      customRender: "productType",
    },
  },
  {
    title: "价值（付款价）",
    dataIndex: "couponPrice",
    align: "center",
  },
  {
    title: "操作",
    align: "center",
    scopedSlots: {
      customRender: "operation",
    },
  }
]
const userColumns = [
  {
    title: "序号",
    align: "center",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "姓名",
    align: "center",
  },
  {
    title: "手机号",
    align: "center",
  },
  {
    title: "身份证号",
    align: "center",
  },
  {
    title: "操作",
    align: "center",
    scopedSlots: {
      customRender: "operation",
    },
  }
]
// 商品列表
const categorycolumns = [{
    title: "序号",
    align: "center",
    dataIndex: "index",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "商品类型",
    align: "center",
    dataIndex: "productType",
    scopedSlots: {
      customRender: "productType",
    },
  },
  {
    align: "center",
    dataIndex: "productName",
    scopedSlots: {
      title: "num",
    },
  },
];
// 商品选中
const cselectcolumns = [{
    align: "center",
    dataIndex: "productName",
    width: 120,
    colSpan: 2,
    scopedSlots: {
      customRender: "productName",
      title: "num",
    },
  },
  {
    title: "操作",
    align: "center",
    colSpan: 0,
    width: 20,
    dataIndex: "operation",
    scopedSlots: {
      customRender: "operation",
    },
  },
];
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      minVolumes:0, // 优惠券最少设置张数
      PreventLoad:false,
      productColumns,
      userColumns,
      operation: 1,
      id:0,
      tableData:[],
      total:0,
      publicConfig: false,
      isReceive: false,
      form: {
        name: '', //优惠劵名称
        description: '', //优惠券描述
        receiveDesc: '', //领取说明
        type: 1, // 目前只有满减券配置默认值为1      优惠劵类型 1.满减劵2.兑换劵3.折扣劵
        fullPrice: '', //满额金额
        couponPrice: '', //优惠金额
        noVolumes: '', //数量控制 0 不限量 1限量
        volumes: '', //优惠券张数
        scope: '', //适用商品1.全场通用2.指定商品类型3.指定商品
        productJson: '',
        overly: '', //是否可以叠加：0否1是
        publicConfig: '', //公开配置 1.商品详情页展示
        astrict: '', //领取条件 0全员领取 1部分领取 2全部不可领取
        receiveAging: '', //领取时间 1.长期 2.指定时间
        startLed: '', //领劵开始时间
        endLed: '', //领劵结束时间
        times: '', //使用时效1.不限2.有限，3.领取后n天
        startTime: '', //使用开始时间
        endTime: '', //	使用结束时间
        days: '', //领取后几天有效
      },

      //* 指定商品选择弹窗数据 */
      productModalShow: false,
      categoryLoad:false, // 商品查询加载
      categorytype: undefined, // 商品类型
      categoryname:'', // 商品名称
      categoryData: [],
      ctotal: 0,
      cpageNumber: 1, // 页码
      cpageSize: 5, // 页数
      selectedRowKey: [],
      categorycolumns,// 选择商品
      disabledKeys:[], //禁止操作商品
      productArry:[],

      cselectcolumns,// 已选商品
      selectData:[],
      spageNumber: 1, // 页码
      spageSize: 5, // 页数

      allGoodList:[
        { id: 0, classify:'全部'},
        { id: 1, classify:'课程'},
        { id: 2, classify:'图书'},
        { id: 3, classify:'教具'},
        { id: 4, classify:'模拟考试'},
        { id: 5, classify:'电子照片'},
        { id: 6, classify:'延期'},
        { id: 7, classify:'补考'},
        { id: 8, classify:'直播'},
      ],
    }
  },
  // 事件处理器
  methods: {
    getDetail(){
      this.$ajax({
        url: "/hxclass-management/new-coupon/detail",
        params: {
          id: this.id
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.form = res.data
          this.publicConfig = (this.form.publicConfig ? true : false) // 公开配置数据转换

          // 当前优惠券已被领取
          if(this.isReceive){
            this.minVolumes = res.data.volumes
          }

          if(res.data.scope == 3){
            res.data.productList.forEach(element => {
              element.productName = element.name
              element.productType = element.categoryType
              element.isReceive = this.isReceive ? 1 : 0
            });
            if(this.isReceive){
              this.disabledKeys = res.data.productList.map(obj => {return obj.productId})
            }
            this.tableData = res.data.productList
          }
        }
      });
    },

    // 数据校验
    funCheck(){
      let isNull = false
      if(!this.form.name){
        this.$message.warning('请输入【优惠券名称】')
        isNull = true
      } else if(this.form.receiveDesc === ''){
        this.$message.warning('请输入【领取说明】')
        isNull = true
      } else if(this.form.fullPrice === ''){
        this.$message.warning('请填写满额金额')
        isNull = true
      } else if(this.form.couponPrice === ''){
        this.$message.warning('请填写优惠金额')
        isNull = true
      } else if(this.form.noVolumes === ''){
        this.$message.warning('请配置【数量控制】')
        isNull = true
      } else if(this.form.noVolumes == 1 && !this.form.volumes){
        this.$message.warning('请在【数量控制】设置优惠券数量')
        isNull = true
      } else if(this.form.scope === ''){
        this.$message.warning('请配置【适用商品】')
        isNull = true
      } else if(this.form.scope == 3 && !this.tableData.length){
        this.$message.warning('请配置指定商品')
        isNull = true
      } else if(this.form.overly === ''){
        this.$message.warning('请配置【优惠叠加】')
        isNull = true
      } else if(this.form.astrict === ''){
        this.$message.warning('请配置【用户领取条件】')
        isNull = true
      } else if(this.form.receiveAging === ''){
        this.$message.warning('请配置【领取时间】')
        isNull = true
      } else if(this.form.receiveAging == 2 && !this.form.startLed){
        this.$message.warning('请设置【领取时间】开始时间')
        isNull = true
      } else if(this.form.receiveAging == 2 && !this.form.endLed){
        this.$message.warning('请设置【领取时间】结束时间')
        isNull = true
      } else if(this.form.times === ''){
        this.$message.warning('请配置【使用时间】')
        isNull = true
      } else if(this.form.times == 2 && !this.form.startTime){
        this.$message.warning('请设置【使用时间】结束时间')
        isNull = true
      } else if(this.form.times == 2 && !this.form.endTime){
        this.$message.warning('请设置【使用时间】结束时间')
        isNull = true
      } else if(this.form.times == 3 && !this.form.days){
        this.$message.warning('请设置【使用时间】结束时间')
        isNull = true
      }
      return isNull
    },

    // 新建/编辑
    onSave(){
      if(this.funCheck()){
        return
      }
      this.form.publicConfig = (this.publicConfig ? 1 : 0) // 公开配置数据转换

      let productjson = []
      if(this.form.scope == 3){
        this.tableData.forEach(element => {
          // productType 商品大类 1.商品 2套餐 3直播
          productjson.push({productId: element.productId, productType: 1})
        });
        this.form.productJson = JSON.stringify(productjson)
      } else {
        this.form.productJson = ''
      }

      // 重置领取时间
      if(this.form.receiveAging != 2){
        this.form.startLed = ''
        this.form.endLed = ''
      }

      // 重置使用时间
      if(this.form.times != 2){
        this.form.startTime = null
        this.form.endTime = null
      } 
      if(this.form.times != 3){
        this.form.days = null
      }

      const url = this.id ? '/hxclass-management/new-coupon/edit' : '/hxclass-management/new-coupon/insert'
      const method = this.id ? 'PUT' : 'POST'
      this.PreventLoad = true
      this.$ajax({
        url: url,
        method: method,
        params: this.form,
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success(res.message)
          this.$router.go(-1)
        } else {
          this.$message.error(res.message)
        }
        this.PreventLoad = false
      });
    },

    onDel(index){
      this.tableData.splice(index,1)
    },
    onSearch() {
      this.cpageNumber = 1
      this.onCategory()
    },
    popupFilling() {
      this.selectData = JSON.parse(JSON.stringify(this.tableData));
      var productIds = this.tableData.map(obj => {return obj.productId})
      this.selectedRowKey = productIds

      this.onSearch()
      this.productModalShow = true;
    },
    categoryCancel(e) {
      this.productModalShow = false;
    },
    // 商品弹窗
    oncPage(e) {
      this.cpageNumber = e.current;
      this.cpageSize = e.pageSize;
      this.onCategory()
    },
    onSelect(selectedRowKeys,v){
      this.selectedRowKey = selectedRowKeys
      this.productArry[this.cpageNumber - 1] = v

      // 暂存数组
      let storageArry = []
      this.productArry.forEach(item => {
        item.forEach(subItem => {
          storageArry.push(subItem)
        });
      });

      // 已选数组和新数组合并
      this.selectData = [...this.selectData, ...storageArry]
      let arry = []
      selectedRowKeys.forEach(element => {
        this.selectData.forEach(item => {
          if(element == item.productId)
            arry.push(item)
          });
      });

      // 并列数组去重
      let newArr = [];
      let obj = {};
      for (var i = 0; i < arry.length; i++) {
        if (!obj[arry[i].productId]) {
          newArr.push(arry[i])
          obj[arry[i].productId] = true
        }
      }

      this.selectData = newArr
    },
    disCheckboxProps(record){
      return {
        props: {
          disabled: this.disabledKeys.indexOf(record.productId) != -1 // 禁选项
        }
      };
    },

    categoryOk() {
      this.productModalShow = false;
      this.tableData = JSON.parse(JSON.stringify(this.selectData));
    },
    deleteGoods(e,x) {
      let nbr = -1;
      if(x){
        if(x == 1){
          nbr = this.ctableData.indexOf(e);
          this.cselectData.splice(nbr, 1);
          this.ctableData.splice(nbr, 1);
          this.selectedRowKeys.splice(nbr, 1);
        }else if(x == 2){
          nbr = this.ptableData.indexOf(e);
          this.pselectData.splice(nbr, 1);
          this.ptableData.splice(nbr, 1);
          this.selectedRowKeyr.splice(nbr, 1)
        }
      }else{
        nbr = this.selectData.indexOf(e);
        this.selectData.splice(nbr, 1);
        this.selectedRowKey.splice(nbr, 1);
      } 
    },
    onCategory(){
      this.categoryLoad = true
      this.$ajax({
        url: "/hxclass-management/product-archives/manage/select-win",
        params: {
          noGround: 1,
          name: this.categoryname,
          type: this.categorytype == 0 ? '' : this.categorytype,
          page: this.cpageNumber,
          size: this.cpageSize,
        },
      }).then((res) => {
        this.categoryLoad = false
        if (res.code == 200 && res.success) {
          this.ctotal = res.data.total;
          this.categoryData = res.data.records;
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created () {
    this.operation = this.$route.query.operation;
    if (this.operation != 1) {
      this.id = this.$route.query.id;
      this.isReceive = ((this.$route.query.drawnum == 0 || !this.$route.query.drawnum) ? false : true);
      this.getDetail();
    }
  },
  // 生命周期-实例挂载后调用
  mounted () {
    this.onCategory()
   },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.all_content_box {
  .L_R_inner {
    margin: 24px;
    .all_left_name {
      width: 130px;
      text-align: right;
    }

    .right_Div {
      .all_input {
        width: 337px;
      }
      /deep/.ant-input {
        height: 37px;
      }
      .else_input {
        margin-right: 12px;
        width: 140px;
      }
      .left {
        margin-left: 12px;
      }
    }

    .wrap_Div{
      flex-wrap: wrap;
      .child_Div{
        margin-top: 10px;
        width: 100%;
        display: flex;
        >div{
          margin-right: 50px;
        }
      }
      .table-template{
        min-width: 700px;
        margin-top: 0;
      }
    }
  }
}
.button{
  margin-left: 70px;
  .btn{
    margin-right: 20px;
  }
}


/deep/.ant-calendar-picker{
  width: 190px;
}
/deep/.ant-modal-body {
  overflow: hidden;
}
/deep/.ant-modal-title {
  text-align: center;
  color: #000000;
}
/deep/.ant-modal-header {
  border-bottom: none;
}
/deep/.ant-modal-footer {
  margin-top: 20px;
  text-align: center;
  border-top: none;
}
/deep/ .ant-modal-body {
  padding: 14px 36px 0px;
}
.table-box {
  display: flex;
  justify-content: space-between;
  .table-template{
    width: 48%;
  }
}
.table-template {
  /deep/.ant-table {
    border: none;

  }
}
.search-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  .search-input {
    width: 36%;
  }
}
</style>
